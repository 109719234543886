<template>
  <div>
    <online-warning></online-warning>
    <in-page-menu />
    <v-row class="mx-4 mx-md-6 mx-lg-8 pt-20">
      <v-dialog
        max-width="840"
        scrollable
        transition="dialog-transition"
        v-model="dialog.showDialog"
      >
        <v-card>
          <v-card-title class="px-6 pb-0 d-flex justify-space-between">
            <h4 class="ma-0">Reads</h4>

            <v-icon @click="dialog.showDialog = false">mdi-close</v-icon>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="py-2 px-6" style="max-height: 600px">
            <v-data-table
              :headers="dialogTableHeaders"
              :items="dialog.table.data"
              :loading="dialog.table.loading"
              class="dashboard-data-table"
              mobile-breakpoint="0"
              must-sort
            >
              <template #item.datetime="{ item }">
                {{ $utils.renderValueAs(item.datetime, "datetime", true) }}
              </template>
              <template #item.eid="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.animalId },
                  }"
                  class="subtitle-2"
                  target="_blank"
                  v-if="item.animalId"
                >
                  {{ item.eid }}
                </router-link>
                <div v-else>
                  {{ item.eid }}
                </div>
              </template>
              <template #item.visual="{ item }">
                <router-link
                  :to="{
                    name: 'AnimalDetails',
                    query: { id: item.animalId },
                  }"
                  class="subtitle-2"
                  target="_blank"
                  v-if="item.animalId"
                >
                  {{ item.visual }}
                </router-link>
                <div v-else>
                  {{ item.visual }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn @click="dialog.showDialog = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col cols="12" class="pa-lg-8">
        <div id="app">
          <div v-if="loading">
            <v-progress-circular
              color="primary"
              indeterminate
              size="40"
              width="5"
            ></v-progress-circular>
          </div>

          <!--- Messages --->
          <div v-if="!isOnline" class="alert alert-danger" role="alert">
            You are offline. Report generation is disabled.
          </div>
          <div v-if="messages.danger.length > 0">
            <div
              :key="index"
              class="alert alert-danger"
              role="alert"
              v-for="(message, index) in messages.danger"
            >
              {{ message }}
            </div>
          </div>
          <div v-if="messages.warning.length > 0">
            <div
              :key="index"
              class="alert alert-warning"
              role="alert"
              v-for="(message, index) in messages.warning"
            >
              {{ message }}
            </div>
          </div>
          <div v-if="messages.info.length > 0">
            <div
              :key="index"
              class="alert alert-info"
              role="alert"
              v-for="(message, index) in messages.info"
            >
              {{ message }}
            </div>
          </div>
          <div v-if="messages.success.length > 0">
            <div
              :key="index"
              class="alert alert-success"
              role="alert"
              v-for="(message, index) in messages.success"
            >
              {{ message }}
            </div>
          </div>

          <div>
            <div class="g-row align-center" style="max-width: 350px">
              <div class="g-col g-col-1">
                <v-subheader class="subtitle-1 pl-0">
                  {{ getTranslation("startDate") }}
                </v-subheader>
              </div>
              <div class="g-col g-col-2">
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="showStartDateCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :disabled="!readerGroups.length || !canGenerateReport()"
                      append-icon="mdi-calendar"
                      class="custom-field"
                      dense
                      hide-details
                      outlined
                      readonly
                      tabindex="1"
                      v-bind="attrs"
                      v-model="startDate"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    :max="endDate"
                    @input="showStartDateCalendar = false"
                    v-model="startDate"
                  />
                </v-menu>
              </div>
            </div>
            <div class="g-row align-center" style="max-width: 350px">
              <div class="g-col g-col-1">
                <v-subheader class="subtitle-1 pl-0">
                  {{ getTranslation("endDate") }}
                </v-subheader>
              </div>
              <div class="g-col g-col-2">
                <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="showEndDateCalendar"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :disabled="!readerGroups.length || !canGenerateReport()"
                      append-icon="mdi-calendar"
                      class="custom-field"
                      dense
                      hide-details
                      outlined
                      readonly
                      tabindex="2"
                      v-bind="attrs"
                      v-model="endDate"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    @input="showEndDateCalendar = false"
                    v-model="endDate"
                  />
                </v-menu>
              </div>
            </div>
            <div class="g-row align-center" style="max-width: 350px">
              <div class="g-col g-col-1">
                <v-subheader class="subtitle-1 pl-0">
                  {{ getTranslation("reader") }}
                </v-subheader>
              </div>
              <div class="g-col g-col-2">
                <v-select
                  :items="readerGroups"
                  :menu-props="{
                    offsetY: true,
                    closeOnClick: true,
                  }"
                  class="custom-field"
                  dense
                  hide-details
                  item-text="label"
                  outlined
                  return-object
                  style="max-width: 234px"
                  v-model="readerGroup"
                />
              </div>
            </div>
            <div class="g-row mt-4">
              <div class="g-col" style="max-width: 200px">
                <v-btn
                  :disabled="!canGenerateReport() || !readerGroups.length"
                  @click="generateReport"
                  class="white--text text-decoration-none"
                  color="primary"
                  depressed
                  min-width="40"
                >
                  {{ getTranslation("Generate") }}
                </v-btn>
              </div>
            </div>
            <div v-if="loaded.report && report.readerGroup">
              <br />
              <br />
              <h2 class="no-print">{{ report.readerGroup.label }}</h2>
              <v-data-table
                :headers="tableHeaders"
                :items="table.data"
                :loading="table.loading"
                class="dashboard-data-table"
                fixed-header
                height="440"
                mobile-breakpoint="0"
                must-sort
              >
                <template #item.date="{ item }">
                  {{ $utils.renderValueAs(item.date, "date", true) }}
                </template>
                <template #item.actions="{ item }">
                  <v-btn
                    @click="openDialog(item.uniqueEIDs)"
                    class="white--text text-decoration-none"
                    color="#757575"
                    depressed
                    min-width="40"
                    small
                  >
                    {{ getTranslation("view") }}
                  </v-btn>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TranslationMixin from "../mixins/Translations";
import Vue from "vue";
import axios from "axios";

export default {
  name: "DisplayAnimals",
  metaInfo: {
    title: "Display Animals",
  },
  mixins: [TranslationMixin],
  data: () => ({
    endDate: moment().endOf("day").format("YYYY-MM-DD"),
    dialog: {
      reads: [],
      showDialog: false,
      table: {
        data: [],
        loading: false,
      },
    },
    fastTrackReport: null,
    herdMeta: null,
    loaded: {
      dialog: true,
      herdMeta: false,
      readerGroups: false,
      report: true,
    },
    messages: {
      danger: [],
      info: [],
      success: [],
      warning: [],
    },
    readerGroup: null,
    readerGroups: [],
    report: {
      datesWithReads: [],
      dayTotalsByType: {},
      readerGroup: null,
    },
    showEndDateCalendar: false,
    showStartDateCalendar: false,
    startDate: moment().startOf("day").subtract(6, "days").format("YYYY-MM-DD"),
    table: {
      loading: false,
      data: [],
    },
    useMockData: false, // Enable this for easy debugging (org: Star S Ranch)
  }),
  computed: {
    ...mapGetters({
      getOrganizationItems: "Organization/getOrganizationItems",
      getToken: "User/getToken",
    }),
    loading: function () {
      return (
        !this.loaded.dialog ||
        !this.loaded.herdMeta ||
        !this.loaded.report ||
        !this.loaded.readerGroups
      );
    },
    dialogTableHeaders: function () {
      return [
        {
          text: this.getTranslation("Effective Date"),
          value: "datetime",
        },
        { text: this.getTranslation("EID"), value: "eid" },
        { text: this.getTranslation("visual"), value: "visual" },
        { text: this.getTranslation("alleyName"), value: "alleyName" },
        { text: this.getTranslation("antenna"), value: "antenna" },
        { text: this.getTranslation("location"), value: "location" },
      ];
    },
    tableHeaders: function () {
      return [
        {
          sortable: false,
          text: this.getTranslation("actions"),
          value: "actions",
        },
        { text: this.getTranslation("displayAnimals.date"), value: "date" },
        { text: this.getTranslation("displayAnimals.lf"), value: "lf" },
        { text: this.getTranslation("displayAnimals.uhf"), value: "uhf" },
        { text: this.getTranslation("displayAnimals.total"), value: "total" },
      ];
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;

    this.herdMeta.loaded
      .fail(() => {
        Vue.notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: "There was a opening the database. Are you still logged in?",
        });
      })
      .done(() => {
        this.loaded.herdMeta = true;
      });

    const { data } = await axios.get(`/api/readers/${this.$organizationID}`, {
      params: {
        token: this.getToken,
      },
    });
    this.readerGroups = HerdMeta.groupOriginReaders(data, false);
    this.loaded.readerGroups = true;

    if (!this.readerGroups.length) {
      this.messages.info.push("Your organization appears to have 0 readers.");
    } else {
      this.readerGroup = this.readerGroups[0];
    }
  },
  methods: {
    canGenerateReport: function () {
      return (
        this.isOnline &&
        this.readerGroup &&
        this.readerGroups.length &&
        HealthTrackerReport.datesAreProper(this.startDate, this.endDate)
      );
    },
    openDialog: function (reads) {
      this.loaded.dialog = false;
      this.dialog.table.loading = true;

      this.dialog.reads = Object.keys(reads)
        .map((eid) => Object.assign({}, reads[eid]))
        .sort(({ dateRecorded: a }, { dateRecorded: b }) => a.localeCompare(b))
        .reverse();

      this.herdMeta
        .getIDoptions(true)
        .catch(console.error)
        .then(() => {
          const promises = this.dialog.reads.map(
            (detail) =>
              new Promise((resolve, reject) => {
                detail.animalId = null;
                detail.location = null;
                detail.visual = null;

                detail.alleyName = detail.reader.friendlyName;
                detail.antenna = detail.reader.antenna;

                this.herdMeta
                  .getFirstAnimalWithActiveTagAndWhetherIsUniqueV2(
                    detail.eid,
                    null,
                    false,
                    false
                  )
                  .then((match) => {
                    if (!match.animal) return resolve();

                    detail.animalId = match.animal.guid;
                    detail.location = match.animal.derived.currents.location;
                    detail.visual =
                      match.animal.derived.summaries.main.visualTagValues;

                    resolve();
                  })
                  .catch(reject);
              })
          );

          Promise.all(promises)
            .catch(console.error)
            .then(() => {
              this.dialog.table.data = this.dialog.reads;
              this.dialog.showDialog = true;
            })
            .finally(() => {
              this.dialog.table.loading = false;
              this.herdMeta.freeUpSearchSpace();
              this.loaded.dialog = true;
            });
        });
    },
    notifySuccess: function (message) {
      this.$notify({
        group: "forms",
        text: message || "Success",
        title: "Success",
        type: "success",
      });
    },
    generateReport: function () {
      this.loaded.report = false;
      this.table.loading = true;

      HerdMeta.getReadsForAllReadersAsync(
        this.readerGroup.readers,
        this.startDate,
        moment(this.endDate).endOf("day"),
        this.getToken
      )
        .catch((e) => {
          this.loaded.report = true;
          console.error(e);

          this.$notify({
            group: "forms",
            type: "error",
            title: "Error",
            text: e,
          });
        })
        .then((results) => {
          this.loaded.report = true;
          this.report.readerGroup = this.readerGroup;

          this.report.dayTotalsByType = {};
          results.forEach((result) => {
            if (this.report.dayTotalsByType[result.dateRecorded] == undefined)
              this.report.dayTotalsByType[result.dateRecorded] = {
                lf: {},
                uhf: {},
                uniqueEIDs: {},
              };

            const tagType = result.reader.isUHF ? "uhf" : "lf";
            if (
              this.report.dayTotalsByType[result.dateRecorded][tagType][
                result.eid
              ] == undefined
            ) {
              this.report.dayTotalsByType[result.dateRecorded][tagType][
                result.eid
              ] = [];
            }
            this.report.dayTotalsByType[result.dateRecorded][tagType][
              result.eid
            ].push(result);

            if (
              this.report.dayTotalsByType[result.dateRecorded].uniqueEIDs[
                result.eid
              ] == undefined ||
              this.report.dayTotalsByType[result.dateRecorded].uniqueEIDs[
                result.eid
              ].datetime < result.datetime
            ) {
              this.report.dayTotalsByType[result.dateRecorded].uniqueEIDs[
                result.eid
              ] = result;
            }
          });

          this.report.datesWithReads = Object.keys(
            this.report.dayTotalsByType
          ).sort();

          this.table.data = this.report.datesWithReads.map((date) => ({
            date,
            lf: Object.keys(this.report.dayTotalsByType[date].lf).length,
            uniqueEIDs: this.report.dayTotalsByType[date].uniqueEIDs,
            uhf: Object.keys(this.report.dayTotalsByType[date].uhf).length,
            total: Object.keys(this.report.dayTotalsByType[date].uniqueEIDs)
              .length,
          }));

          this.$notify({
            group: "forms",
            type: "success",
            title: "Success",
            text: "Report is complete",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
};
</script>
<style scoped>
input {
  border: 1px solid #ccc;
}
</style>
